import { ValueOf } from 'types/generic';

// Centralized Enum for All Event Names
export const enum TrackingEvents {
  LOGIN = 'login',
  ARCHIVE_VIEWED = 'archive_viewed',
  ADOPTION_MODAL_OPENED = 'adoption_modal_opened',
  INVOICE_APPROVED = 'invoice_approved',
  APPROVAL_REQUESTED = 'approval_requested',
  DOCUMENT_PREVIEW_OPENED = 'document_preview_opened',
  DOCUMENT_DOWNLOADED = 'document_downloaded',
  PROMOTION_MODAL_OPENED = 'promotion_modal_opened',
  FILTER_OPENED = 'filter_opened',
  FILTER_APPLIED = 'filter_applied',
  CONTACT_SUPPLIER_OPENED = 'contact_supplier_opened',
  DEMO_BOOKING_STARTED = 'demo_booking_started',
  ERROR_VIEWED = 'error_viewed',
  EXPENSE_COLLECTION_SPLIT_OPENED = 'expense_collection_split_opened',
  EXPENSE_COLLECTION_ACCEPT_SPLIT_CLICKED = 'expense_collection_accept_split_clicked',
  EXPENSE_COLLECTION_APPROVED = 'expense_collection_approved',
  EXPENSE_COLLECTION_SUBMITTED = 'expense_collection_submitted',
  EXPENSE_COLLECTION_REJECTED = 'expense_collection_rejected',
  DOCUMENT_COMPARED = 'document_compared',
  DOCUMENT_RELATION_OPENED = 'document_relation_opened',
  REIMBURSEMENT_DOCUMENT_UPLOADED = 'reimbursement_document_uploaded',
  REIMBURSEMENT_CASE_CREATED = 'reimbursement_case_created',
  // Add other events as needed
}

// Centralized Enum for All Adoption Names
export const enum AdoptionFeatures {
  MOBILE_APP = 'mobile app',
  // Add other adoption features as needed
}

// Centralized Enum for All Promotion Names
export const enum PromotionFeatures {
  DOCUMENT_PREVIEW = 'document preview',
  // Add other promotion features as needed
}

export const enum EventLocationsBase {
  MAIN_DASHBOARD = 'main dashboard',
  ARCHIVE = 'archive',
  SETTINGS = 'settings',
  EXPORT = 'export',
  INBOX = 'Inbox',
  APPROVAL = 'approval',
  REIMBURSEMENT_DASHBOARD = 'reimbursement dashboard',
  GLOBAL_SEARCH = 'global_search',
}

export const enum DocumentPreviewEventLocations {
  ARCHIVE = 'archive',
  MANAGE_RELATIONSHIPS = 'manage relationships',
}

interface LoginEventProperties {
  keycloak_auth_client: string;
  keycloak_session_id: string;
  user_agent: string;
}

interface ArchiveViewedProperties {
  archive_documents_count: number;
}

interface AdoptionModalOpenedProperties {
  feature: ValueOf<AdoptionFeatures>;
  event_location?: string;
}

interface PromotionModalOpenedProperties {
  feature: ValueOf<PromotionFeatures>;
  event_location?: string;
}

interface DemoBookingStartedProperties {
  feature: ValueOf<PromotionFeatures>;
  event_location?: string;
}

interface FilterOpenedProperties {
  event_location: EventLocationsBase.GLOBAL_SEARCH;
}

interface FilterAppliedProperties {
  event_location: EventLocationsBase.GLOBAL_SEARCH;
}

export interface ContactSupplierOpenedProperties {
  event_location: 'document_summary_card' | 'e_invoice_error_details';
  document_id: string;
  e_invoice_error_count: number;
}

export interface ErrorViewedProperties {
  event_location: 'e_invoice_error';
  document_id: string;
}

export interface InvoiceApprovedProperties {
  document_id: string;
  note?: string | null;
  tax_code?: string | null;
  posting_text?: string | null;
  accounts_payable?: string | null;
  cost_center?: string | null;
  cost_object?: string | null;
  extra_cost_info?: string | null;
  general_ledger_account?: string | null;
  social_artist_security?: string | null;
  is_fast_approval: boolean;
  is_last_step: boolean;
}

type SuggestionConfig = {
  no_suggestion: boolean;
  case_based_suggestion: boolean;
  expense_based_suggestion: boolean;
};

export interface ReimbursmentEntityProperties {
  event_location?: string;
  reimbursement_id?: string;
  reimbursement_date?: string;
  expense_notice?: string;
  expense_id?: string;
  cost_center?: SuggestionConfig;
  cost_object?: SuggestionConfig;
  general_ledger?: SuggestionConfig;
  expense_type?: string | string[];
}

interface ApprovalRequestedProperties
  extends Omit<
    InvoiceApprovedProperties,
    'is_fast_approval' | 'is_last_step'
  > {}

interface DocumentPreviewOpenedProperties {
  document_id: string;
}

interface DocumentComparedProperties {
  global_document_id: string;
  compared_global_document_id: string;
  compare_view_open: boolean;
}

interface DocumentRelationOpenedProperties {
  global_document_id: string;
  relation_global_document_id: string;
  compare_view_open: boolean;
}

export interface DocumentDownloadedProperties {
  document_id: string;
  event_location:
    | 'document_viewer_header'
    | 'document_kebab_menu'
    | 'document_history';
}

export type EventProperties = {
  [TrackingEvents.LOGIN]: LoginEventProperties;
  [TrackingEvents.ARCHIVE_VIEWED]: ArchiveViewedProperties;
  [TrackingEvents.ADOPTION_MODAL_OPENED]: AdoptionModalOpenedProperties;
  [TrackingEvents.INVOICE_APPROVED]: InvoiceApprovedProperties;
  [TrackingEvents.APPROVAL_REQUESTED]: ApprovalRequestedProperties;
  [TrackingEvents.DOCUMENT_PREVIEW_OPENED]: DocumentPreviewOpenedProperties;
  [TrackingEvents.PROMOTION_MODAL_OPENED]: PromotionModalOpenedProperties;
  [TrackingEvents.FILTER_OPENED]: FilterOpenedProperties;
  [TrackingEvents.FILTER_APPLIED]: FilterAppliedProperties;
  [TrackingEvents.CONTACT_SUPPLIER_OPENED]: ContactSupplierOpenedProperties;
  [TrackingEvents.DEMO_BOOKING_STARTED]: DemoBookingStartedProperties;
  [TrackingEvents.ERROR_VIEWED]: ErrorViewedProperties;
  [TrackingEvents.EXPENSE_COLLECTION_SPLIT_OPENED]: ReimbursmentEntityProperties;
  [TrackingEvents.EXPENSE_COLLECTION_ACCEPT_SPLIT_CLICKED]: ReimbursmentEntityProperties;
  [TrackingEvents.EXPENSE_COLLECTION_APPROVED]: ReimbursmentEntityProperties;
  [TrackingEvents.EXPENSE_COLLECTION_REJECTED]: ReimbursmentEntityProperties;
  [TrackingEvents.EXPENSE_COLLECTION_SUBMITTED]: ReimbursmentEntityProperties;
  [TrackingEvents.DOCUMENT_COMPARED]: DocumentComparedProperties;
  [TrackingEvents.DOCUMENT_RELATION_OPENED]: DocumentRelationOpenedProperties;
  [TrackingEvents.REIMBURSEMENT_CASE_CREATED]: ReimbursmentEntityProperties;
  [TrackingEvents.REIMBURSEMENT_DOCUMENT_UPLOADED]: ReimbursmentEntityProperties;
  [TrackingEvents.DOCUMENT_DOWNLOADED]: DocumentDownloadedProperties;
  // Add other events and properties as needed
};
